:root {
  --color-dark-blue: #035072;
  --color-light-blue: #00a0d6;
  --color-yellow: #fbcb42;
  --color-light-light-blue: #d8eef6;
  --color-green: #95c34b;
  --color-orange: #f0a14b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.offInputStyle:focus {
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* anitmations */

.animation-scale:hover {
  transform: scale(1.05);
}

.btn-warning {
  background-color: var(--color-yellow);
}

.btn-success {
  background-color: var(--color-green);
}

/* styles */

input {
  border-radius: 10px;
  padding: 5px 5px 5px 15px;
  border: 1px solid lightgray;
}

input::file-selector-button {
  border: 2px solid var(--color-dark-blue);
  border-radius: 15px;
  background-color: transparent;
  font-style: italic;
  color: var(--color-dark-blue);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.box-style {
  border: 1px solid var(--color-dark-blue);
  background-color: var(--color-light-light-blue);
}

.shipping-input {
  width: 100px;
}

.check {
  background: var(--color-green);
  color: white;
  scale: 1.2;
  border: 1px solid white;
}

.notCheck {
  border: 1px solid black;
}

.queue tr:nth-child(2n + 1) {
  background: lightgrey;
  padding: 9px;
}

.list tr:nth-child(2n + 1) {
  background: lightgrey;
  padding: 9px;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background: rgb(200, 200, 200); */
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--color-dark-blue);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-blue);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-dark-blue);
}

.sticky-header.styled-table {
  margin: 0;
}

.sticky-header.styled-table th  {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--color-dark-blue);
}

.styled-table {
  overflow-x: scroll;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: left;
}

.wrong-table thead tr {
  background-color: #dc3545;
}

.good-table thead tr {
  background-color: var(--color-green);
}

.styled-table th,
.styled-table td {
  text-align: center;
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* .styled-table thead tr {
  border-bottom: 2px solid #009879;
} */

.font-big td {
  font-size: 14px;
  font-weight: bold;
}

.font-big th {
  font-size: 12px;
}

#file-upload-button {
  background: red;
}

.bold {
  font-weight: bold;
}

.tooltip-custom {
  position: relative;
  display: inline-block;
}

.tooltip-custom .tooltiptext-custom {
  font-size: 15px;
  visibility: hidden;
  width: 120px;
  background-color: var(--color-dark-blue);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext-custom ::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  font-family: "Roboto";
}

.tooltip-custom:hover .tooltiptext-custom {
  visibility: visible;
  opacity: 1;
}
